import Service from "@/services/email";
//import {router} from '@/plugins/router'

export default {
  namespaced: false,
  actions: {
    postEmail({ dispatch },payload) {
      Service.post(payload)
        .then(() => {
          dispatch("alert/success", "Votre message a été envoyé avec succès.");
        })
        .catch(() => {
          dispatch("alert/error", "Il y a eu une erreur lors de l'envoi du message.");
        });
    },
  }
};

export default {
  namespaced: true,
  state: {
    type: null,
    message: null,
    toggle: false,
  },
  actions: {
    success({ commit }, message) {
      commit("success", message);
    },
    error({ commit }, message) {
      commit("error", message);
    },
    clear({ commit }, message) {
      commit("success", message);
    },
  },
  mutations: {
    success(state, message) {
      state.type = "success";
      state.message = message;
      state.toggle = !state.toggle;
    },
    error(state, message) {
      console.log(message);
      state.type = "danger";
      state.message = message;
      state.toggle = !state.toggle;
      console.log(state.toggle);
    },
    clear(state) {
      state.type = null;
      state.message = null;
    },
  },
};

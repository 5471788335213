import Service from "@/services/formulaires";
//import {router} from '@/plugins/router'

export default {
  namespaced: false,
  state: {
    list: {}
  },
  actions: {
    getPostes({ commit }) {
      Service.get()
        .then((data) => {
          commit("posteList", data.data);
        })
        .catch(() => {
        });
    },
  },
  mutations: {
    posteList(state, list) {
      state.list = list;
    }
  },
};

import Vue from "vue";
import Vuex from "vuex";

import formulaires from "@/store/formulaires.module";
import alert from "@/store/alert.module";
import email from "@/store/email.module";
import lang from "@/store/lang.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    formulaires,
    alert,
    email,
    lang
  },
  state: {},
  mutations: {},
  actions: {},
});

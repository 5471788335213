let lang;
try {
    lang = JSON.parse(localStorage.getItem("lang"));
} catch (err) {
    console.warn("No access to local storage");
}

let language = window.navigator.userLanguage || window.navigator.language;
if (language.indexOf("fr") !== -1) {
    language = "fr";
} else {
    language = "en";
}
const initialLang = lang ? lang : { value: language };
export default {
    namespaced: false,
    state: {
        ...initialLang,
    },
    actions: {
        setLangValue({ commit }, lang) {
            commit("setLangValue", lang);
        }
    },
    getters: {
        getViewed: (state) => {
            let apartements = [];
            state.viewed.forEach(function(id) {
                apartements.push(
                    state.list.find((apartment) => apartment.frontId === id)
                );
            });

            return apartements;
        },
    },
    mutations: {
        setLangValue(state, value) {
            state.value = value;
            try {
                localStorage.setItem("lang", JSON.stringify(state));
            } catch (err) {
                console.warn("No access to local storage");
            }
        },
    },
};